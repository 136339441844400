import React from 'react';
import Button from "components/Button";
import styled, {withTheme} from 'styled-components'
import {useNavigate} from "react-router-dom";
import {Card} from "components/Card";
import {Link} from "components/Link";

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
`

const Instructions = styled.div`
  margin-bottom: 2rem;
  font-weight: 500;
`

const Row = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  > :last-child {
    max-width: 50rem;
  }
`

const Heading = styled.h3``

const Section = styled.div`
  font-size: 1.4rem;
`


const SettingsScreen = ({theme}) => {
    const navigate = useNavigate();
    return <React.Fragment>
        <Header>
            <Button variant={"secondary"} onClick={() => navigate(-1)}>Go back</Button>
        </Header>
        <Card title={"Proof Customer Support"}>
            <Heading>Questions? We’re Here to Help.</Heading>
            <Section>
                Whether you’re looking for general sales and account level support or questions specific to an ongoing serve request, our team is standing by to assist.
            </Section>

            <Heading>Serve Support</Heading>
            <Section>
            Have a question regarding an ongoing serve? Within your {theme.name} matter, select the serve in question to access our in-job chat located on the right side of the screen. This chat window allows you to directly communicate with both your assigned Process Server and Proof. This is the best location for you to receive real-time serve updates.
            </Section>

            <Heading>All Other Support Questions</Heading>
            <Section>
            Click on the <Link to={APP_URL} target="_blank">Open Proof</Link> button at the bottom of each page. The Open Proof link will open the Proof website where you are able to access our live chat support to assist with any questions you have. There’s no need for you to sign in, our platform communicates directly with {theme.name} so you can seamlessly access Proof without any additional steps once you have logged into {theme.name}.<br />
            Our Live Chat support is available Monday through Friday from 9:00 AM - 9:00 PM Eastern time.
            </Section>

            <Heading>Additional Resources:</Heading>
            <Section>
                <Link to={"https://www.proofserve.com/pricing"} target="_blank">Proof’s Complete Pricing for All States</Link><br />
                Email Support: <Link to={"mailto:support@proofserve.com"} target="_blank">support@proofserve.com</Link>
            </Section>
        </Card>
    </React.Fragment>
};

export default withTheme(SettingsScreen);
