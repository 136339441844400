import { Checkbox } from "components/Checkbox";
import { Input } from "components/Input";
import Loader from "components/Loader";
import Select from "components/Select";
import { useToast } from "components/Toast";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { createAddress, getServePricings, getStates } from "utils/api";
import Modal from "components/Modal";
import { Required } from "components/Required";

const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

const Label = styled.label`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  > * {
    flex: 1;
    margin-bottom: 0 !important;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
`;

const NewPricingWarning = styled.div`
  font-size: 1.5rem;
  color: #991b1e;
  font-weight: 400;
  margin-top: 1.2rem;
`;

const AddAddressModal = ({ theme, job, open, onSubmit, onClose }) => {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState({
    type: "",
    address_1: "",
    address_2: "",
    city: "",
    state_id: null,
    zip: "",
    primary: false,
  });
  const { addToast } = useToast();
  const [newPrice, setNewPrice] = useState(0);
  getStates()
    .then((s) => setStates(s))
    .catch(console.error);

  const submit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (
      !address.type ||
      !address.address_1 ||
      !address.city ||
      !address.state_id ||
      !address.zip
    ) {
      addToast(
        "error",
        "Missing required fields",
        "Please fill out all required fields"
      );
      return;
    }
    setLoading(true);
    createAddress(job.id, address)
      .then(() => {
        onSubmit(e);
        setLoading(false);
        addToast("success", "Success", "Address added to serve request");
      })
      .catch(() => {
        setLoading(false);
        addToast("error", "Error", "Error adding address");
      });
  };

  const setNewAddressFee = (value) => {
    address.fee_amount = value;
  };

  const determineNewAddressFee = () => {
    const baseFee = job.base_fee;

    if (job.attempts_made === 0) {
      setNewAddressFee(0);
    } else if (job.law_firm?.percentage_discount) {
      setNewAddressFee(
        baseFee - baseFee * (job.law_firm?.percentage_discount / 100)
      );
    } else if (
      job.same_address_job &&
      job.law_firm?.same_address_discount_enabled
    ) {
      setNewAddressFee(baseFee / 2);
    } else {
      setNewAddressFee(baseFee);
    }
  };

  const addressFilled = () =>
    address &&
    address.address_1 &&
    address.state_id &&
    address.city &&
    address.zip;

  const loadPricings = () => {
    if (!addressFilled()) {
      return;
    }
    getServePricings(
      address.address_1,
      address.city,
      address.state_id,
      address.zip
    )
      .then((res) => {
        res.pricings.forEach((pricing) => {
          if (
            pricing.name === job.serve_pricing.name &&
            job.base_fee + job.bounty_fee !==
              pricing.base_fee + pricing.bounty_fee
          ) {
            setNewPrice(pricing.base_fee + pricing.bounty_fee);
          }
        });
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    determineNewAddressFee();
  }, []);

  useEffect(() => {
    const timeut = setTimeout(() => {
      loadPricings();
    }, 500);

    return () => clearTimeout(timeut);
  }, [address.address_1, address.city, address.state_id, address.zip]);

  return (
    <Modal
      title="Add address"
      open={open}
      onConfirm={submit}
      onClose={onClose}
      loading={loading}
      confirmText="Add address"
    >
      {loading && <Loader />}
      {!loading && (
        <form onSubmit={submit}>
          <InputGroup>
            <InputRow>
              <Label>
                Type
                <Required />
              </Label>
              <Select
                options={[
                  { value: "HOME", label: "Home" },
                  { value: "WORK", label: "Work" },
                  { value: "REGISTERED_AGENT", label: "Registered Agent" },
                  { value: "ENTITY", label: "Entity" },
                  { value: "OTHER", label: "Other" },
                ]}
                placeholder="Choose a type..."
                value={address.type}
                required={true}
                onChange={(val) => setAddress({ ...address, type: val })}
                theme={theme}
              />
            </InputRow>
          </InputGroup>
          <InputGroup>
            <InputRow>
              <Label>
                Address 1
                <Required />
              </Label>
              <Input
                type="text"
                value={address.address_1}
                required={true}
                onChange={(val) => setAddress({ ...address, address_1: val })}
                theme={theme}
              />
            </InputRow>
            <InputRow>
              <Label>Address 2</Label>
              <Input
                type="text"
                value={address.address_2}
                onChange={(val) => setAddress({ ...address, address_2: val })}
                theme={theme}
              />
            </InputRow>
          </InputGroup>
          <InputGroup>
            <InputRow>
              <Label>
                City
                <Required />
              </Label>
              <Input
                type="text"
                value={address.city}
                required={true}
                onChange={(val) => setAddress({ ...address, city: val })}
                theme={theme}
              />
            </InputRow>
            <InputRow>
              <Label>
                State
                <Required />
              </Label>
              <Select
                options={states.map((s) => ({ value: s.id, label: s.name }))}
                placeholder="Choose a state..."
                value={address.state_id}
                required={true}
                onChange={(val) => setAddress({ ...address, state_id: val })}
                theme={theme}
              />
            </InputRow>
            <InputRow>
              <Label>
                Zip
                <Required />
              </Label>
              <Input
                type="text"
                value={address.zip}
                required="required"
                onChange={(val) => setAddress({ ...address, zip: val })}
                theme={theme}
              />
            </InputRow>
          </InputGroup>
          <Checkbox
            title="Primary address"
            value={address.primary}
            onChange={(val) => setAddress({ ...address, primary: val })}
            theme={theme}
          />
        </form>
      )}
    </Modal>
  );
};

export default AddAddressModal;
