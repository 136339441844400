import React from 'react';
import styled, {css} from 'styled-components'
import {clio} from "themes/clio";
import {myCase} from "themes/mycase";

const variantStyles = (theme, variant = 'primary') => {
    switch (theme) {
        case myCase:
            return css`
              border-collapse: collapse;
              background: #fff;
              border-radius: .4rem;

              thead {
                background: #fff;
                border-top: 1px solid #e1e1e1;
                border-bottom: 2px solid #e1e1e1;
              }

              th, td {
                padding: 1.2rem;
              }

              th {
                text-transform: uppercase;
                font-weight: normal;
                font-size: 1.2rem;
                color: #8e8e8e;
              }

              tr:not(:last-child) td {
                border-bottom: 1px solid #eceff1;
              }
              
              tbody > tr:nth-child(odd) > td {
                background: rgba(0, 0, 0, 0.03);
              }
            `
        case clio:
        default:
            return css`
              border-collapse: collapse;
              background: #fff;
              border-radius: .3rem;
              box-shadow: 0 0 0 1px #b0bec5;

              thead {
                background-color: #F5F7F8;
              }

              th, td {
                padding: 1.2rem;
              }

              th {
                border-bottom: 1px solid #b0bec5;

                &:not(:last-child) {
                  border-right: 1px solid #b0bec5;
                }
              }

              tr:not(:last-child) td {
                border-bottom: 1px solid #eceff1;
              }

            `
    }
};

const StyledTable = styled.table`
  width: 100%;

  th {
    text-align: left;
  }

  ${({theme, variant}) => variantStyles(theme, variant)}
`;

const renderField = (row, field) => {
    if (field.display) {
        return field.display(row);
    }
    return field.field.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : undefined;
    }, row || self);
}

export const Table = ({fields, data}) => (
    <StyledTable>
        <thead>
        <tr>
            {fields.map((field, i) => <th key={i}>{field.title}</th>)}
        </tr>
        </thead>
        <tbody>
        {data.map((row, i) => <tr key={i}>
            {fields.map((field, i) => <td key={i}>{renderField(row, field)}</td>)}
        </tr>)}
        </tbody>
    </StyledTable>
);