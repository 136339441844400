import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getServiceAddOns } from '../../utils/api';
import { RadioGroup } from '../../components/RadioGroup';
import Button from '../../components/Button';
import { formatNumber } from '../../utils/format';
import { useToast } from '../../components/Toast';
import {
  InputRow,
  Label,
  Section,
  SectionBody,
  SectionHeader,
  SectionHeaderIcon,
  SectionHeaderTitle,
} from './JobCreationScreen';

export const Mailing = ({ addMailing, setAddMailing, job, setServeeField }) => {
  const { addToast } = useToast();

  const [prices, setPrices] = useState({
    firstClass: { amount: 0, trigger: 'MAILING_FIRST_CLASS' },
    certified: { amount: 0, trigger: 'MAILING_CERTIFIED' },
  });

  useEffect(() => {
    if (addMailing) {
      getServiceAddOns('name', 'asc', [
        prices.firstClass.trigger,
        prices.certified.trigger,
      ])
        .then((resp) => {
          const mailingPrices = {};
          resp.data?.forEach((addOn) => {
            addOn.slug === 'mailing-first-class' &&
              (mailingPrices.firstClass = addOn);
            addOn.slug === 'mailing-certified' &&
              (mailingPrices.certified = addOn);
          });
          setPrices(mailingPrices);
        })
        .catch((err) => {
          addToast('error', 'Error', 'Error fetching service add-ons');
          console.error('error fetching service add-ons', err);
        });
    }
  }, [addMailing]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAddMailingChange = (v) => {
    setAddMailing(Boolean(v));
    if (!v) {
      job.servees.forEach((_, i) => {
        setServeeField(i, 'letter_trigger', 'NONE');
        setServeeField(i, 'letter_is_certified', false);
      });
    }
  };

  const onSelectMailingOptions = (prop, val) => {
    job.servees.forEach((_, i) => {
      setServeeField(i, prop, val);
    });
  };

  return (
    <Section key="add-ons">
      <SectionHeader>
        <SectionHeaderTitle>
          <SectionHeaderIcon>
            <svg
              className="w-[30px] h-[30px] text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M2 5.6V18c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V5.6l-.9.7-7.9 6a2 2 0 0 1-2.4 0l-8-6-.8-.7Z" />
              <path d="M20.7 4.1A2 2 0 0 0 20 4H4a2 2 0 0 0-.6.1l.7.6 7.9 6 7.9-6 .8-.6Z" />
            </svg>
          </SectionHeaderIcon>
          Mailing
        </SectionHeaderTitle>
      </SectionHeader>

      <SectionBody>
        <InputRow>
          <Label>
            Would you like to ensure due diligence by adding mailing to your
            serve job?
          </Label>
          <RadioGroup
            name="add-mailing"
            defaultValue={addMailing}
            options={[
              { id: 'mail-yes', label: 'Yes', value: true },
              { id: 'mail-no', label: 'No', value: false },
            ]}
            onChange={onAddMailingChange}
          />
        </InputRow>

        {addMailing && (
          <>
            <InputRow>
              <Label>When should we mail the documents?</Label>
              <RadioGroup
                defaultValue={job.servees[0].letter_trigger}
                name="letter-trigger"
                options={[
                  {
                    id: 'closeout-success',
                    label: 'Mail only if serve is successful',
                    value: 'CLOSEOUT_SUCCESS',
                  },
                  {
                    id: 'closeout-failure',
                    label: 'Mail only if serve is unsuccessful',
                    value: 'CLOSEOUT_FAILURE',
                  },
                  {
                    id: 'closeout-any',
                    label: 'Always mail, regardless of the outcome',
                    value: 'CLOSEOUT_ANY',
                  },
                ]}
                onChange={(val) => {
                  onSelectMailingOptions('letter_trigger', val);
                }}
              />
            </InputRow>

            <InputRow>
              <MailingOptions>
                <MailingOption selected={!job.servees[0].letter_is_certified}>
                  <MailingOptionTitle>First Class</MailingOptionTitle>
                  <MailingOptionPrice>
                    ${formatNumber(prices.firstClass.amount)}
                  </MailingOptionPrice>

                  <MailingOptionDescr>
                    With delivery times typically ranging between 1-3 business
                    days, this cost-effective option provides a reliable way to
                    send essential documents.
                  </MailingOptionDescr>

                  {job.servees[0].letter_is_certified && (
                    <Button
                      variant="primary"
                      onClick={() =>
                        onSelectMailingOptions('letter_is_certified', false)
                      }
                    >
                      Select
                    </Button>
                  )}
                  {!job.servees[0].letter_is_certified && <b>Selected</b>}
                </MailingOption>
              </MailingOptions>
            </InputRow>
          </>
        )}
      </SectionBody>
    </Section>
  );
};

const MailingOptions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: -0.6rem;
`;

const MailingOption = styled.div`
  padding: 2rem;
  margin: 0.6rem;
  transition: background 200ms ease-in-out;
  background: #f5f7f8;
  border: 1px solid #e2ebee;
  text-align: center;
  ${({ selected }) => (selected ? `background: #abd8f6;` : ``)}
  max-width: 20%;
  border-radius: 0.2rem;
`;

const MailingOptionTitle = styled.div`
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
`;

const MailingOptionPrice = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 1.2rem;
`;

const MailingOptionDescr = styled.div`
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
`;

const MailingOptionError = styled.div`
  font-weight: 500;
`;
