import React, {useEffect} from 'react';
import styled, {css, withTheme} from 'styled-components'
import {clio} from "themes/clio";
import Button from "components/Button";
import Loader from "components/Loader";
import {myCase} from "themes/mycase";

const variantStyles = (theme) => {
    switch (theme) {
        case clio:
        default:
            return css`
              background-color: ${({theme}) => theme.colors.white};
              border-radius: .4rem;
            `
    }
};

const variantHeaderStyles = (theme) => {
    switch (theme) {
        case myCase:
            return css`
              font-size: 2rem;
              font-weight: 500;
              padding: 1.35rem 2rem;
              border-bottom: 1px solid #ccd9e0;
              background: rgba(0,0,0,0.03);;
              border-top-left-radius: .4rem;
              border-top-right-radius: .4rem;
            `
        case clio:
        default:
            return css`
              font-size: 2rem;
              font-weight: 700;
              padding: 1.35rem 2rem;
              border-bottom: 1px solid #ccd9e0;
              background: #eceff1;
              border-top-left-radius: .4rem;
              border-top-right-radius: .4rem;
            `
    }
};

const variantBodyStyles = (theme) => {
    switch (theme) {
        case clio:
        default:
            return css`
              padding: 2rem;
            `
    }
};

const variantBodySyles = (theme) => {
    switch (theme) {
        case clio:
        default:
            return css`
              color: ${({theme}) => theme.colors.link};
              text-decoration: underline;
            `
    }
};

const variantFooterStyles = (theme) => {
    switch (theme) {
        case myCase:
            return css`
              background: #eceff1;
              border-top: 1px solid #ccd9e0;
              padding: 0 2rem;
              height: 4.8rem;
              min-height: 4.8rem;
              display: flex;
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;

              > *:not(:last-child) {
                margin-right: .8rem;
              }
            `
        case clio:
        default:
            return css`
              background: #eceff1;
              border-top: 1px solid #ccd9e0;
              padding: 0 2rem;
              height: 4.8rem;
              min-height: 4.8rem;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              > *:not(:last-child) {
                margin-right: .8rem;
              }
            `
    }
};

const Root = styled.div`
  z-index: 3;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: ${({open}) => open ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
`

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 60rem;
  max-width: 75%;
  max-height: 100%;
  ${({theme, variant}) => variantStyles(theme, variant)}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  ${({theme, variant}) => variantHeaderStyles(theme, variant)}
`;

const Body = styled.div`
  ${({theme, variant}) => variantBodyStyles(theme, variant)}
  ${({noPadding}) => noPadding ? `padding: 0;` : null}
`;

const Footer = styled.div`
  width: 100%;

  > *:not(:last-child) {
    margin-right: 1rem;
  }

  ${({theme, variant}) => variantFooterStyles(theme, variant)}
`

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
`
export const Modal = ({
                          theme,
                          title,
                          children,
                          noPadding,
                          confirmText,
                          open,
                          cancelText,
                          onConfirm,
                          onClose,
                          loading = false,
                          confirmVariant = "primary",
                          hideCancel = false,
                          hideConfirm = false
                      }) => {

    const onKeyDown = (e) => {
        if (open && (e.key === 'Escape' || e.keyCode === 27)) {
            onClose();
        }
    };

    useEffect(() => {
        if (open) {
            window.addEventListener('keydown', onKeyDown);
            if (theme?.windowMessageModalSupport && window.parent) {
                window.parent.postMessage('PROOF_LPMS_MODAL_OPEN', '*');
            }
        }
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            if (theme?.windowMessageModalSupport && window.parent) {
                window.parent.postMessage('PROOF_LPMS_MODAL_CLOSE', '*');
            }
        };

    }, [open]);

    if (!open) {
        return null;
    }

    return <Root open={open}>
        <StyledCard>
            <Header>
                {title}
                <CloseButton onClick={onClose}>
                    <svg viewBox="0 -256 1792 1792" id="svg3001" version="1.1" width="20px" height="20px"
                         fill="#667881">
                        <g transform="matrix(1,0,0,-1,205.01695,1262.6441)">
                            <path
                                d="m 1298,214 q 0,-40 -28,-68 L 1134,10 q -28,-28 -68,-28 -40,0 -68,28 L 704,304 410,10 q -28,-28 -68,-28 -40,0 -68,28 L 138,146 q -28,28 -28,68 0,40 28,68 L 432,576 138,870 q -28,28 -28,68 0,40 28,68 l 136,136 q 28,28 68,28 40,0 68,-28 l 294,-294 294,294 q 28,28 68,28 40,0 68,-28 l 136,-136 q 28,-28 28,-68 0,-40 -28,-68 L 976,576 1270,282 q 28,-28 28,-68 z"/>
                        </g>
                    </svg>
                </CloseButton>
            </Header>
            <Body noPadding={noPadding}>
                {loading && <Loader/>}
                {!loading && children}
            </Body>
            {(!hideConfirm || !hideCancel) &&
                <Footer>
                    {!hideConfirm && <Button variant={confirmVariant ?? "primary"} onClick={onConfirm}>{confirmText ?? 'Confirm'}</Button>}
                    {!hideCancel && <Button variant={"secondary"} onClick={onClose}>{cancelText ?? 'Cancel'}</Button>}
                </Footer>
            }
        </StyledCard>
    </Root>
};

export default withTheme(Modal);