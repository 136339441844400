import styled, {css} from 'styled-components'
import {clio} from "themes/clio";

const variantStyles = (theme, variant = 'primary') => {
    switch (theme) {
        case clio:
        default:
            const base = css`
              color: ${props => props.theme.colors.white};
              border-radius: 4.2rem;
              padding: 0 .8rem;
              font-size: 1.1rem;
              font-weight: 500;
              line-height: 1.8rem;
            `
            return {
                primary: css`
                  ${base};
                  background-color: ${theme.colors.primary};
                `,
                secondary: css`
                  ${base};
                  background-color: #f5f7f8;
                  color: #001c37;
                `,
                warning: css`
                  ${base};
                  background-color: ${theme.colors.warning};
                  color: ${theme.colors.text};
                `,
                info: css`
                  ${base};
                  background-color: ${theme.colors.info};
                `,
                success: css`
                  ${base};
                  background-color: ${theme.colors.success};
                `,
                destructive: css`
                  ${base};
                  background-color: ${theme.colors.destructive};
                `,
            }[variant];
    }
};

export default styled.div`
  display: inline-block;
  position: relative;
  ${({theme, variant}) => variantStyles(theme, variant)}
`;