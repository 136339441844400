import React from 'react';
import styled, {css} from 'styled-components'
import {clio} from "themes/clio";
import {Link as ReactLink} from "react-router-dom";
const variantStyles = (theme) => {
    switch (theme) {
        case clio:
        default:
            return css`
              color: ${({theme}) => theme.colors.link};
              text-decoration: underline;
            `
    }
};

const StyledLink = styled(ReactLink)`
  ${({theme, variant}) => variantStyles(theme, variant)}
`;

const StyledAnchor = styled.a`
  ${({theme, variant}) => variantStyles(theme, variant)}
`;

export const Link = ({to, target, theme, children}) => {
    if (to && (to.startsWith('http://') || to.startsWith('https://'))) {
        return <StyledAnchor href={to} target={target}>{children}</StyledAnchor>
    }
    return   <StyledLink to={to}>{children}</StyledLink>
};