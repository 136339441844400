import React from 'react';
import {withTheme} from 'styled-components'
import ReactSelect, {components} from 'react-select';
import {clio} from "themes/clio";
import {myCase} from "themes/mycase";


const variantStyles = (theme) => {
    switch (theme) {
        case myCase:
            return {
                container: (provided, state) => ({
                    ...provided,
                    fontSize: '1.3rem',
                }),
                control: (provided, state) => ({
                    ...provided,
                    borderRadius: '.2rem',
                    minHeight: '3.2rem',
                    borderColor: '#a1a1a1',
                    boxShadow: state.isFocused ? '0 0 3px #006bc0;' : provided.borderColor
                }),
                indicatorSeparator: (provided, state) => ({
                    ...provided,
                    height: '100%',
                    marginTop: 0,
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    padding: '.5rem 1.05rem',
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#0070c0" : state.isFocused ? "#f2faff" : "white",
                }),

            };
        case clio:
        default:
            return {
                container: (provided, state) => ({
                    ...provided,
                    fontSize: '1.3rem',
                }),
                control: (provided, state) => ({
                    ...provided,
                    borderRadius: '.2rem',
                    minHeight: '3.2rem',
                    borderColor: state.isFocused ? '#9ed8ff !important' : '#ccd9e0',
                    boxShadow: state.isFocused ? '0 0 0 2px #9ed8ff;' : provided.borderColor
                }),
                indicatorSeparator: (provided, state) => ({
                    ...provided,
                    height: '100%',
                    marginTop: 0,
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    padding: '.5rem 1.05rem',
                }),
                option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#006cd8" : state.isFocused ? "#f2faff" : "white",
                }),

            };
    }
};

const variantComponents = (theme) => {
    switch (theme) {
        case clio:
        default:
            return {
                DropdownIndicator: props => <components.DropdownIndicator {...props}>
                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Layer_1" width="7.5" height="6.5"
                         viewBox="0 0 531.74 460.5" overflow="visible" enableBackground="new 0 0 531.74 460.5"
                         xmlSpace="preserve">
                        <polygon stroke="#000000" points="530.874,0.5 265.87,459.5 0.866,0.5 "/>
                    </svg>
                </components.DropdownIndicator>
            };
    }
};

const Select = ({theme, placeholder, options, value, onChange, multi}) => {
    return <ReactSelect placeholder={placeholder}
                 options={options}
                 value={value ? (multi ? value.map(v => ({value: v, label: options.find(o => o.value == v)?.label})) : {value: value, label: options.find(o => o.value == value)?.label}) : null}
                 styles={variantStyles(theme)}
                 components={variantComponents(theme)}
                 isMulti={multi}
                 onChange={val => onChange(multi ? val.map(v => v.value) : val.value)}/>
};

export default withTheme(Select)