import React from 'react';
import styled, {css} from 'styled-components'
import {clio} from "themes/clio";
import {Link as ReactLink} from "react-router-dom";
import {myCase} from "themes/mycase";

const variantStyles = (theme) => {
    switch (theme) {
        case myCase:
            return css`
              color: ${theme.colors.primary};
              font-weight: 400;
            `
        case clio:
        default:
            return css`
              color: #339931;
              font-weight: 400;
            `
    }
};

const StyledRequired = styled.div`
  ${({theme, variant}) => variantStyles(theme, variant)}
`;

export const Required = () => {
    return <StyledRequired>Required</StyledRequired>
};