import React from 'react';
import styled from 'styled-components'


const Label = styled.label`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 1.3rem;
`

const Input = styled.input`
  margin-right: .8rem;
`

export const Checkbox = ({title, value, onChange, disabled}) => (
    <Label>
        <Input type={"checkbox"}
               checked={value}
               disabled={disabled}
               onChange={e => onChange(e.target.checked)}/>
        {title}
    </Label>
);