import {createGlobalStyle} from "styled-components";

export const litify = {
    id: 'LITIFY',
    name: 'Litify',
    windowMessageModalSupport: true,
    matter: 'matter',
    global: createGlobalStyle`
      @font-face {
        font-family: "Helvetica Neue";
        src: url(/fonts/helvetica-neue/helvetica-neue-light.ttf) format('ttf');
        font-weight: 300;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: "Helvetica Neue";
        src: url(/fonts/helvetica-neue/helvetica-neue-regular.ttf) format('ttf');
        font-weight: 400;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: "Helvetica Neue";
        src: url(/fonts/helvetica-neue/helvetica-neue-medium.ttf) format('ttf');
        font-weight: 500;
        font-style: normal;
        font-display: fallback;
      }

      @font-face {
        font-family: "Helvetica Neue";
        src: url(/fonts/helvetica-neue/helvetica-neue-bold.ttf) format('ttf');
        font-weight: 700;
        font-style: normal;
        font-display: fallback;
      }

      html {
        font-size: 10px;
        font-weight: 400;
        background: ${props => props.theme.colors.background}
      }

      body {
        color: ${props => props.theme.colors.text};
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        margin: 0;
        padding: 2rem;
      }

      * {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      }
    `,
    colors: {
        primary: '#0075bb', // $blue
        link: '#0070e0', // $blue-link
        primary_bg: '#d7edfb', // $blue-200
        primary_hover: '#004670', // $blue-dark
        secondary: '#00b980', // $mint
        secondary_bg: '#d1f9ed', // $mint-200
        secondary_hover: '#00b980', // $mint-500
        info: '#0055a9',
        info_hover: '#2163c7',
        info_bg: 'rgba(0, 85, 169, .15)',
        background: '#f5f7f8', // $grey-1
        white: '#fff',
        black: '#000',
        destructive: '#e41b28', // $red
        destructive_hover: '#a40618', // $red-dark
        destructive_bg: '#ffe5e7', // $red-200
        warning: '#fdaa1e',
        warning_hover: '#d58600',
        warning_bg: 'rgba(247, 159, 31, .15)',
        success: '#0ca65e',
        success_hover: '#01a057',
        success_bg: 'rgba(12, 166, 94, .15)',
        text: '#263238',
    },
};
