import {createGlobalStyle} from "styled-components";

export const myCase = {
    id: 'MYCASE',
    name: 'MyCase',
    windowMessageModalSupport: false,
    matter: 'case',
    global: createGlobalStyle`
      @font-face {
        font-family: 'neue-haas-grotesk-text';
        font-weight: 500;
        font-style: italic;
        font-display: fallback;
        //font-family: 'Neue Haas Grotesk Std Text 55 Italic';
        src: url('/fonts/neue-haas-grotesk/2F0FA1_0_0.eot');
        src: url('/fonts/neue-haas-grotesk/2F0FA1_0_0.eot?#iefix') format('embedded-opentype'), 
        url('/fonts/neue-haas-grotesk/2F0FA1_0_0.woff2') format('woff2'),
        url('/fonts/neue-haas-grotesk/2F0FA1_0_0.woff') format('woff'), 
        url('/fonts/neue-haas-grotesk/2F0FA1_0_0.ttf') format('truetype');
      }


      @font-face {
        font-family: 'neue-haas-grotesk-text';
        font-weight: 500;
        font-style: normal;
        font-display: fallback;
        //font-family: 'Neue Haas Grotesk Std Text 55 Roman';
        src: url('/fonts/neue-haas-grotesk/2F0FA1_1_0.eot');
        src: url('/fonts/neue-haas-grotesk/2F0FA1_1_0.eot?#iefix') format('embedded-opentype'),
        url('/fonts/neue-haas-grotesk/2F0FA1_1_0.woff2') format('woff2'),
        url('/fonts/neue-haas-grotesk/2F0FA1_1_0.woff') format('woff'),
        url('/fonts/neue-haas-grotesk/2F0FA1_1_0.ttf') format('truetype');
      }

      @font-face {
        font-family: 'neue-haas-grotesk-text';
        font-weight: 700;
        font-style: normal;
        font-display: fallback;
        //font-family: 'Neue Haas Grotesk Std Text 75 Bold';
        src: url('/fonts/neue-haas-grotesk/2F0FA1_3_0.eot');
        src: url('/fonts/neue-haas-grotesk/2F0FA1_3_0.eot?#iefix') format('embedded-opentype'),
        url('/fonts/neue-haas-grotesk/2F0FA1_3_0.woff2') format('woff2'),
        url('/fonts/neue-haas-grotesk/2F0FA1_3_0.woff') format('woff'),
        url('/fonts/neue-haas-grotesk/2F0FA1_3_0.ttf') format('truetype');
      }


      @font-face {
        font-family: 'neue-haas-grotesk-text';
        font-weight: 800;
        font-style: normal;
        font-display: fallback;
        //font-family: 'Neue Haas Grotesk Std Display 95 Black';
        src: url('/fonts/neue-haas-grotesk/2F0FA1_2_0.eot');
        src: url('/fonts/neue-haas-grotesk/2F0FA1_2_0.eot?#iefix') format('embedded-opentype'),
        url('/fonts/neue-haas-grotesk/2F0FA1_2_0.woff2') format('woff2'),
        url('/fonts/neue-haas-grotesk/2F0FA1_2_0.woff') format('woff'),
        url('/fonts/neue-haas-grotesk/2F0FA1_2_0.ttf') format('truetype');
      }


      html {
        font-size: 10px;
        font-weight: 400;
        background: ${props => props.theme.colors.background}
      }

      body {
        color: ${props => props.theme.colors.text};
        font-family: neue-haas-grotesk-text, "Helvetica Neue", -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        margin: 0;
        padding: 2rem;
      }

      * {
        font-family: neue-haas-grotesk-text, "Helvetica Neue", -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif;
      }
    `,
    colors: {
        primary: '#0070c0', // $blue
        link: '#0070c0', // $blue-link
        primary_bg: '#d7edfb', // $blue-200
        primary_hover: '#004670', // $blue-dark
        secondary: '#00b980', // $mint
        secondary_bg: '#d1f9ed', // $mint-200
        secondary_hover: '#00b980', // $mint-500
        info: '#0055a9',
        info_hover: '#2163c7',
        info_bg: 'rgba(0, 85, 169, .15)',
        background: '#fff', // $grey-1
        white: '#fff',
        black: '#000',
        destructive: '#e41b28', // $red
        destructive_hover: '#a40618', // $red-dark
        destructive_bg: '#ffe5e7', // $red-200
        warning: '#fdaa1e',
        warning_hover: '#d58600',
        warning_bg: 'rgba(247, 159, 31, .15)',
        success: '#0ca65e',
        success_hover: '#01a057',
        success_bg: 'rgba(12, 166, 94, .15)',
        text: '#263238',
    },
};
