import Modal from "components/Modal";
import React, {useState} from "react";
import styled from "styled-components";
import {cancelJob} from "utils/api";
import {useToast} from "components/Toast";
import {Input} from "components/Input";
import {STATUS_ASSIGNED, STATUS_UNASSIGNED} from "domain/job";
import {Required} from "components/Required";

const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`

const Label = styled.label`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: .5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Message = styled.div`
  font-weight: 500;
  margin-bottom: 1.2rem;
`

const CancelJobModal = ({theme, job, open, onSubmit, onClose}) => {
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState('');
    const {addToast} = useToast();

    const submit = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        if (!reason || reason.length <= 0) {
            addToast('error', 'Missing required fields', 'Please fill out flag description');
            return;
        }
        setLoading(true);
        cancelJob(job.id, reason).then(() => {
            onSubmit(e);
            setLoading(false);
            addToast('success', 'Success', 'Serve request cancelled');
        }).catch(() => {
            setLoading(false);
            addToast('error', 'Error', 'Error cancelling serve request');
        })
    };

    return <Modal title={"Cancel serve request"}
                  open={open}
                  onConfirm={submit}
                  onClose={onClose}
                  loading={loading}
                  cancelText={"Nevermind"}
                  confirmVariant={"destructive"}
                  confirmText={"Cancel serve request"}>
        <form onSubmit={submit}>
            {job.status === STATUS_UNASSIGNED && <Message>Because this job has not been assigned yet you will not be charged.</Message>}
            {job.status === STATUS_ASSIGNED && <Message>Because this job has not not had any attempts you will not be charged unless the server is currently en route.</Message>}
            {[STATUS_UNASSIGNED, STATUS_ASSIGNED].indexOf(job.status) === -1 && <Message>Because this job is in progress and an attempt has been made you may still be charged.</Message>}
            <InputRow>
                <Label>
                    Reason for cancellation:
                    <Required />
                </Label>

                <Input type={"textarea"}
                       value={reason.description}
                       onChange={val => setReason(val)}
                       theme={theme}/>
            </InputRow>
        </form>
    </Modal>
};

export default CancelJobModal;