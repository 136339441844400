import React from 'react';

const Logo = ({width = '12.5rem'}) => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
         x="0px"
         y="0px"
         style={{width: width, height: 'auto'}}
         viewBox="0 0 498.42 179.06"
         xmlSpace="preserve">
    <g fill="#041b29">
    <g>
    <path d="M178.58,50.27h35.37c21.28,0,37.06,16.35,37.06,36.78c0,20.43-15.78,36.78-37.21,36.78h-15.64v29.31h-19.59V50.27z
M198.17,68.45v37.21h14.37c11.27,0,18.74-8.46,18.74-18.6c0-10.15-7.47-18.6-18.74-18.6H198.17z"/>
        <path d="M257.31,82.68h19.17v11.56c3.52-7.33,9.86-11.56,19.45-11.56h7.75v18.74h-12.26c-10.29,0-14.8,5.36-14.8,18.46v33.26
h-19.31V82.68z"/>
        <path d="M303.53,117.92c0-20.29,16.21-36.64,36.5-36.64s36.5,16.35,36.5,36.64c0,20.29-16.21,36.64-36.5,36.64
S303.53,138.21,303.53,117.92z M357.78,117.92c0-10.01-8.03-18.32-17.76-18.32c-10.01,0-17.62,8.31-17.62,18.32
c0,10.01,7.61,18.32,17.62,18.32C349.75,136.24,357.78,127.92,357.78,117.92z"/>
        <path d="M380.62,117.92c0-20.29,16.21-36.64,36.5-36.64s36.5,16.35,36.5,36.64c0,20.29-16.21,36.64-36.5,36.64
S380.62,138.21,380.62,117.92z M434.87,117.92c0-10.01-8.03-18.32-17.76-18.32c-10.01,0-17.62,8.31-17.62,18.32
c0,10.01,7.61,18.32,17.62,18.32C426.84,136.24,434.87,127.92,434.87,117.92z"/>
        <path d="M453.89,82.68h11.98v-1.83c0-22.97,7.47-30.58,31-30.58h1.55v18.04c-11.84,0-13.25,1.69-13.25,12.68v1.69h13.25v17.33
h-13.25v53.13h-19.31v-53.13h-11.98V82.68z"/>
</g>
        <path d="M49.68,179.06H8.29c-2.12,0-4.19-0.88-5.67-2.41c-2.73-2.82-3.3-3.41-1.84-47.3c0.02-1.12,0.67-27.25,15.73-47.3
		C9.92,71.79,5.96,59.63,5.38,47.36C4.55,29.98,10.39,13.99,21.81,2.34c3.02-3.08,7.98-3.13,11.06-0.11
		c3.08,3.02,3.13,7.98,0.1,11.06c-15.36,15.66-14.76,39.81-4.99,57.45c12.76-8.9,32.47-14.08,47.02-8.57
		c9.5,3.59,15.49,12.2,15.28,21.93c-0.22,9.82-6.63,18.23-16.34,21.42c-14.87,4.9-34.27,0.15-47.02-11.06
		c-9.94,15.56-10.5,35.06-10.5,35.29c-0.41,12.35-0.72,25.85-0.72,33.65h26.17v-29.19c0-4.32,3.5-7.82,7.82-7.82H71.7
		c21.75,0,43.77-14.88,43.77-43.33c0-18.55-10.71-40.22-40.88-40.22H49.85c-4.32,0-7.82-3.5-7.82-7.82s3.5-7.82,7.82-7.82h24.74
		c17.63,0,32.36,6.03,42.6,17.44c8.98,10.01,13.93,23.65,13.93,38.42c0,15.36-5.48,29.78-15.43,40.62
		c-11.02,12-26.23,18.35-43.98,18.35h-14.2v29.19C57.51,175.55,54,179.06,49.68,179.06z M37.65,83.07c8.61,7.31,21.96,10.7,31.4,7.6
		c5.08-1.67,5.56-5.71,5.59-6.91c0.03-1.49-0.41-5.15-5.18-6.96C60.46,73.39,46.57,77.11,37.65,83.07z"/>
</g>
</svg>

);

export default Logo;