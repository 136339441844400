import Modal from "components/Modal";
import React, { useState } from "react";
import styled from "styled-components";
import { flagJob } from "utils/api";
import Select from "components/Select";
import Loader from "components/Loader";
import { useToast } from "components/Toast";
import {
  getNiceFlagType,
  TYPE_AFFIDAVIT_CHANGES,
  TYPE_BAD_ADDRESS,
  TYPE_MISSING_INFORMATION,
  TYPE_ON_HOLD,
  TYPE_OTHER,
} from "domain/flags";
import { Input } from "components/Input";
import { Required } from "components/Required";

const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

const Label = styled.label`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FlagJobModal = ({ theme, job, open, onSubmit, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [flag, setFlag] = useState({
    type: null,
    description: "",
  });
  const { addToast } = useToast();

  const submit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (!flag.type) {
      addToast(
        "error",
        "Missing required fields",
        "Please fill out flag type."
      );
      return;
    }
    if (!flag.description || flag.description.length <= 0) {
      addToast(
        "error",
        "Missing required fields",
        "Please fill out flag description."
      );
      return;
    }
    setLoading(true);
    flagJob(job.id, flag)
      .then(() => {
        onSubmit(e);
        setLoading(false);
        addToast("success", "Success", "Flag added to serve request");
      })
      .catch(() => {
        setLoading(false);
        addToast("error", "Error", "Error creating flag");
      });
  };

  return (
    <Modal
      title={"Flag serve request"}
      open={open}
      onConfirm={submit}
      onClose={onClose}
      loading={loading}
      confirmText={"Add flag"}
    >
      {loading && <Loader />}
      {!loading && (
        <form onSubmit={submit}>
          <InputRow>
            <Label>
              Reason:
              <Required />
            </Label>

            <Select
              options={[
                {
                  value: TYPE_BAD_ADDRESS,
                  label: getNiceFlagType(TYPE_BAD_ADDRESS),
                },
                {
                  value: TYPE_MISSING_INFORMATION,
                  label: getNiceFlagType(TYPE_MISSING_INFORMATION),
                },
                {
                  value: TYPE_AFFIDAVIT_CHANGES,
                  label: getNiceFlagType(TYPE_AFFIDAVIT_CHANGES),
                },
                {
                  value: TYPE_ON_HOLD,
                  label: getNiceFlagType(TYPE_ON_HOLD),
                },
                {
                  value: TYPE_OTHER,
                  label: getNiceFlagType(TYPE_OTHER),
                },
              ]}
              value={flag.type}
              onChange={(val) => setFlag({ ...flag, type: val })}
              theme={theme}
            />
          </InputRow>
          <InputRow>
            <Label>
              Description:
              <Required />
            </Label>

            <Input
              type={"textarea"}
              value={flag.description}
              onChange={(val) => setFlag({ ...flag, description: val })}
              theme={theme}
            />
          </InputRow>
        </form>
      )}
    </Modal>
  );
};

export default FlagJobModal;
