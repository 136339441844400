import Modal from "components/Modal";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {createAddress, deleteAddress, getStates, updateAddress} from "utils/api";
import Select from "components/Select";
import Loader from "components/Loader";
import {useToast} from "components/Toast";
import {Input} from "components/Input";
import {Checkbox} from "components/Checkbox";
import {formattedAddress} from "domain/address";

const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`

const Label = styled.label`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: .5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Required = styled.span`
  color: #339931;
  font-weight: 400;
`

const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  > * {
    flex: 1;
    margin-bottom: 0 !important;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
`

const DeleteAddressModal = ({theme, address, job, open, onSubmit, onClose}) => {
    const [loading, setLoading] = useState(false);

    const {addToast} = useToast();

    const submit = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        setLoading(true);
        deleteAddress(job.id, address.id).then(() => {
            onSubmit(e);
            setLoading(false);
            addToast('success', 'Success', 'Address deleted successfully');
        }).catch(() => {
            setLoading(false);
            addToast('error', 'Error', 'Error deleting address');
        })
    };

    return <Modal title={"Delete address"}
                  open={open}
                  onConfirm={submit}
                  onClose={onClose}
                  loading={loading}
                  confirmVariant={"destructive"}
                  confirmText={"Delete address"}>
        {loading && <Loader/>}
        {!loading && <form onSubmit={submit}>
            Are you sure you want to delete the address <b>{formattedAddress(address)}</b>?
        </form>}
    </Modal>
};

export default DeleteAddressModal;