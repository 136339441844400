import React, { createContext, useState, useEffect } from "react";
import {getCurrentUser} from "utils/api";

const CurrentUserContext = createContext(null);

const CurrentUserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        getCurrentUser().then(user => setUser(user)).catch(console.error);
    }, []);

    return (
        <CurrentUserContext.Provider value={user}>
            {children}
        </CurrentUserContext.Provider>
    );
};

export { CurrentUserContext, CurrentUserContextProvider };
