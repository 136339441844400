const TYPE_HOME = 'HOME';
const TYPE_WORK = 'WORK';
const TYPE_REGISTERED_AGENT = 'REGISTERED_AGENT';
const TYPE_ENTITY = 'ENTITY';
const TYPE_OTHER = 'OTHER';

export const formattedAddress = (address) =>  {
    let formatted = '';
    if (address.type === TYPE_WORK) {
        formatted += address.business_name ? address.business_name + ', ' : '';
    }
    formatted += address.address_1 ? address.address_1 : '';
    formatted += address.address_2 ? ', ' + address.address_2 : '';
    formatted += address.city ? ', ' + address.city : '';
    formatted += address.state ? ', ' + address.state.abbreviation : '';
    formatted += address.zip ? ' ' + address.zip : '';
    return formatted;
}
