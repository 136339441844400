import {TYPE_PENDING_CANCELLATION} from "domain/flags";

export const STATUS_DRAFT = 'DRAFT';
export const STATUS_PENDING_FILING = 'PENDING_FILING';
export const STATUS_UNASSIGNED = 'UNASSIGNED';
export const STATUS_ASSIGNED = 'ASSIGNED';
export const STATUS_IN_PROGRESS = 'IN_PROGRESS';
export const STATUS_PENDING_NOTARY = 'PENDING_NOTARY';
export const STATUS_IN_REVIEW = 'IN_REVIEW';
export const STATUS_FILING_AFFIDAVIT = 'FILING_AFFIDAVIT';
export const STATUS_PENDING_RATING = 'PENDING_RATING';
export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_CANCELED = 'CANCELED';
export const STATUS_REASSIGNED = 'REASSIGNED';

export const AFFIDAVIT_STATUS_PENDING_TM_REVIEW = 'PENDING_TM_REVIEW';
export const AFFIDAVIT_STATUS_PENDING_QA_REVIEW = 'PENDING_QA_REVIEW';
export const AFFIDAVIT_STATUS_PENDING_EXECUTION = 'PENDING_EXECUTION';

export const OFF_APP_NOT_ASSIGNED = 'NOT_ASSIGNED';
export const OFF_APP_SERVER = 'OFF_APP_SERVER';
export const OFF_APP_SHERIFF = 'SHERIFF';

export const canCancelJob = job => {
    return [STATUS_UNASSIGNED, STATUS_ASSIGNED, STATUS_IN_PROGRESS, STATUS_PENDING_NOTARY].indexOf(
        job.status
    ) !== -1 && (job.flags?.filter(f => f.type === TYPE_PENDING_CANCELLATION && !f.resolved)?.length === 0 ?? true);
};

export const getNiceStatus = (job) => {
    switch (job.status) {
        case STATUS_DRAFT:
            return 'Draft';
        case STATUS_UNASSIGNED:
            return 'Unassigned';
        case STATUS_PENDING_FILING:
            return 'Pending Filing';
        case STATUS_ASSIGNED:
            return 'Assigned';
        case STATUS_IN_PROGRESS:
            return 'In Progress';
        case STATUS_PENDING_NOTARY:
            if (job) {
                return requiresNotary(job) ? 'Pending Notary' : 'Certifying Affidavit';
            }
            return 'Pending Notary';
        case STATUS_IN_REVIEW:
            return 'In Review';
        case STATUS_FILING_AFFIDAVIT:
            return 'Filing Affidavit';
        case STATUS_PENDING_RATING:
            return 'Pending Rating';
        case STATUS_COMPLETED:
            return 'Completed';
        case STATUS_CANCELED:
            return 'Canceled';
        case STATUS_REASSIGNED:
            return 'Reassigned';
        default:
            return job.status;
    }
}

export const requiresNotary = (job) => {
    if (job.serve_pricing && job.serve_pricing.name === 'Envelope') {
        return false;
    } else if (job.force_certify) {
        return false;
    } else if (job.law_firm && job.law_firm.notary_required) {
        return true;
    } else if (job.force_notary) {
        return true;
    } else if (job.federal) {
        return false;
    }
    return (
        (job.state && job.state.requires_notary_service && job.successful_attempt) ||
        (job.state && job.state.requires_notary_nonservice && !job.successful_attempt)
    );
}