import Modal from 'components/Modal';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getStates, updateCurrentLawFirm } from 'utils/api';
import Select from 'components/Select';
import Loader from 'components/Loader';
import { useToast } from 'components/Toast';
import { Input } from 'components/Input';
import { Required } from 'components/Required';

const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

const Label = styled.label`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  > * {
    flex: 1;
    margin-bottom: 0 !important;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
`;

const Instructions = styled.div`
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
  font-weight: bold;
`;

const EditFirmAddressModal = ({ theme, firm, open, onSubmit, onClose }) => {
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updatedAddress, setUpdatedAddress] = useState({
    name: '',
    phone_number: '',
    address_1: '',
    address_2: '',
    city: '',
    state_id: null,
    zip: '',
  });
  const { addToast } = useToast();

  useEffect(() => {
    getStates()
      .then((s) => setStates(s))
      .catch(console.error);
  }, []);

  useEffect(() => {
    setUpdatedAddress({ ...firm });
  }, [firm]);

  const submit = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (
      !updatedAddress.name ||
      !updatedAddress.phone_number ||
      !updatedAddress.address_1 ||
      !updatedAddress.city ||
      !updatedAddress.state_id ||
      !updatedAddress.zip
    ) {
      addToast(
        'error',
        'Missing required fields',
        'Please fill out all required fields',
      );
      return;
    }
    setLoading(true);
    updateCurrentLawFirm(updatedAddress)
      .then(() => {
        onSubmit && onSubmit(e);
        setLoading(false);
        addToast('success', 'Success', 'Address updated successfully');
      })
      .catch(() => {
        setLoading(false);
        addToast('error', 'Error', 'Error updating address');
      });
  };

  return (
    <Modal
      title={'Complete your Proof profile'}
      open={open}
      onConfirm={submit}
      onClose={onClose}
      loading={loading}
      confirmText={'Save'}
    >
      {loading && <Loader />}
      {!loading && (
        <form onSubmit={submit}>
          <Instructions>
            Before you get started, we need a few more details about your firm.
          </Instructions>
          <InputGroup>
            <InputRow>
              <Label>
                Firm Name
                <Required />
              </Label>
              <Input
                type={'text'}
                value={updatedAddress.name}
                required={true}
                onChange={(val) =>
                  setUpdatedAddress({ ...updatedAddress, name: val })
                }
                theme={theme}
              />
            </InputRow>
            <InputRow>
              <Label>
                Phone Number
                <Required />
              </Label>
              <Input
                type={'text'}
                value={updatedAddress.phone_number}
                required={'required'}
                onChange={(val) =>
                  setUpdatedAddress({ ...updatedAddress, phone_number: val })
                }
                theme={theme}
              />
            </InputRow>
          </InputGroup>
          <InputGroup>
            <InputRow>
              <Label>
                Address 1
                <Required />
              </Label>
              <Input
                type={'text'}
                value={updatedAddress.address_1}
                required={true}
                onChange={(val) =>
                  setUpdatedAddress({ ...updatedAddress, address_1: val })
                }
                theme={theme}
              />
            </InputRow>
            <InputRow>
              <Label>Address 2</Label>
              <Input
                type={'text'}
                value={updatedAddress.address_2}
                onChange={(val) =>
                  setUpdatedAddress({ ...updatedAddress, address_2: val })
                }
                theme={theme}
              />
            </InputRow>
          </InputGroup>
          <InputGroup>
            <InputRow>
              <Label>
                City
                <Required />
              </Label>
              <Input
                type={'text'}
                value={updatedAddress.city}
                required={true}
                onChange={(val) =>
                  setUpdatedAddress({ ...updatedAddress, city: val })
                }
                theme={theme}
              />
            </InputRow>
            <InputRow>
              <Label>
                State
                <Required />
              </Label>
              <Select
                options={(states || []).map((s) => ({
                  value: s.id,
                  label: s.name,
                }))}
                placeholder={'Choose a state...'}
                value={updatedAddress.state_id}
                required={true}
                onChange={(val) =>
                  setUpdatedAddress({ ...updatedAddress, state_id: val })
                }
                theme={theme}
              />
            </InputRow>
            <InputRow>
              <Label>
                Zip
                <Required />
              </Label>
              <Input
                type={'text'}
                value={updatedAddress.zip}
                required={'required'}
                onChange={(val) =>
                  setUpdatedAddress({ ...updatedAddress, zip: val })
                }
                theme={theme}
              />
            </InputRow>
          </InputGroup>
        </form>
      )}
    </Modal>
  );
};

export default EditFirmAddressModal;
