import React from 'react';
import styled, {css} from 'styled-components'
import {clio} from "themes/clio";
import {myCase} from "themes/mycase";

const variantInputStyles = (theme) => {
    switch (theme) {
        case myCase:
            return css`
              border: 1px solid #a1a1a1;
              border-radius: .2rem;
              padding: .65rem 1.2rem;
              outline: none;

              &:focus {
                box-shadow: 0 0 3px #006bc0;
              }
            `
        case clio:
        default:
            return css`
              border: 1px solid #ccd9e0;
              border-radius: .2rem;
              padding: .65rem 1.2rem;
              outline: none;

              &:focus {
                border-color: #9ed8ff;
                box-shadow: 0 0 0 2px #9ed8ff;
              }
            `
    }
};

const variantTextAreaStyles = (theme) => {
    switch (theme) {
        case clio:
        case myCase:
            return css`
              border: 1px solid #a1a1a1;
              border-radius: .2rem;
              padding: .65rem 1.2rem;
              outline: none;

              &:focus {
                box-shadow: 0 0 3px #006bc0;
              }
            `
        default:
            return css`
              border: 1px solid #ccd9e0;
              border-radius: .2rem;
              padding: .65rem 1.2rem;
              outline: none;

              &:focus {
                border-color: #9ed8ff;
                box-shadow: 0 0 0 2px #9ed8ff;
              }
            `
    }
};

const StyledInput = styled.input`
  width: 100%;
  background: ${({theme}) => theme.colors.white};
  ${({theme, variant}) => variantInputStyles(theme, variant)}
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  background: ${({theme}) => theme.colors.white};
  ${({theme, variant}) => variantTextAreaStyles(theme, variant)}
`;

export const Input = ({type, rows, value, onChange, placeholder, onKeyPress}) => {
    if (type === 'textarea') {
        return <StyledTextArea type={type}
                               placeholder={placeholder}
                               value={value}
                               rows={rows ?? 5}
                               onChange={e => onChange(e.target.value)}
                               onKeyPress={onKeyPress}/>
    }
    return <StyledInput type={type ?? "text"}
                        placeholder={placeholder}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        onKeyPress={onKeyPress}/>
};