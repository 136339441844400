import React from "react";
import styled from 'styled-components'

const Ctx = React.createContext();

const ToastContainer = props => (
    <div style={{position: "fixed", right: 0, bottom: 0, zIndex: 10}} {...props} />
);

const StyledToast = styled.div`
  border: 1px solid #ccd9e0;
  border-left: 4px solid ${({theme, type}) => ({
      success: '#339931',
      warning: '#fdaa1e',
      error: '#e41b28',
      info: '#0075bb',
  }[type])};
  background: #fff;
  cursor: pointer;
  color: #263238;
  padding: 1.2rem;
  margin: 1rem;
  box-shadow: 0 15px 12px 0 rgb(38 50 56 / 20%);
  max-width: 37.2rem;
`

const Toast = ({children, onDismiss, type}) => (
    <StyledToast onClick={onDismiss} type={type}>
        {children}
    </StyledToast>
);
let toastCount = 0;

export function ToastProvider({children}) {
    const [toasts, setToasts] = React.useState([]);

    const addToast = (type, title, content) => {
        const id = toastCount++;
        const toast = {type, title, content, id};
        setToasts([...toasts, toast]);
        setTimeout(() => {
            removeToast(id);
        }, type === 'error' ? 4000 : 3000);
    };
    const removeToast = id => {
        const newToasts = toasts.filter(t => t.id !== id);
        setToasts(newToasts);
    };
    // avoid creating a new fn on every render
    const onDismiss = id => () => removeToast(id);

    return (
        <Ctx.Provider value={{addToast, removeToast}}>
            {children}
            <ToastContainer>
                {toasts.map(({content, id, title, ...rest}) => (
                    <Toast key={id} Toast={Toast} onDismiss={onDismiss(id)} {...rest}>
                        {title && <b>{title}: </b>}{content}
                    </Toast>
                ))}
            </ToastContainer>
        </Ctx.Provider>
    );
}

export const useToast = () => React.useContext(Ctx);
