import Modal from 'components/Modal';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Input } from 'components/Input';
import { Checkbox } from 'components/Checkbox';
import { createPaymentMethod, setupIntent } from 'utils/api';
import { Required } from 'components/Required';
import { useToast } from 'components/Toast';

const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`;

const Label = styled.label`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Error = styled.div`
  font-weight: 500;
  color: #e41b28;
  margin-bottom: 1.2rem;
`;

const Footer = styled.div`
  width: 100%;

  > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const AddForm = ({ theme, onAdd, onCancel, setDefault = false }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [card, setCard] = useState({
    cardholder_name: '',
    primary: setDefault,
    share: false,
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ackCheckboxValue, setAckCheckboxValue] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    if (loading) {
      return false;
    }

    if (!card.label) {
      setError('Please provide a label');
      return;
    }

    if (!elements || !stripe) {
      setError('Failed to load stripe');
      return;
    }

    setLoading(true);

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: LPMS_URL,
      },
    });

    if (error) {
      setError(error.message);
      setLoading(false);
      return;
    }

    if (setupIntent) {
      onAdd({
        ...card,
        setup_intent_id: setupIntent.id,
      });
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputRow>
        <Label>
          Label
          <Required />
        </Label>
        <Input
          type={'text'}
          value={card.label}
          onChange={(val) => setCard({ ...card, label: val })}
          theme={theme}
        />
      </InputRow>
      <InputRow>
        <Label>
          Credit or debit card
          <Required />
        </Label>
        <PaymentElement
          id="payment-element"
          options={{
            layout: 'tabs',
          }}
        />
      </InputRow>
      <InputRow>
        <Checkbox
          title={'Set as default payment method'}
          value={card.primary}
          onChange={(val) => setCard({ ...card, primary: val })}
          theme={theme}
        />
      </InputRow>
      <InputRow>
        <Checkbox
          title={
            'Share with firm (allow other users at your firm to use this payment method)'
          }
          value={card.share}
          onChange={(val) => setCard({ ...card, share: val })}
          theme={theme}
        />
      </InputRow>
      {error && <Error>{error}</Error>}
      <InputRow>
        <Checkbox
          title={
            <>
              I acknowledge that credit cards will be charged at the time of
              invoice and that ACH accounts will be charged once per month for
              the previous month’s activity (
              <a
                onClick={() => {
                  setShowTermsModal(true);
                }}
              >
                Click here for full details.
              </a>
              )
            </>
          }
          value={ackCheckboxValue}
          onChange={(val) => setAckCheckboxValue(val)}
          theme={theme}
        />
      </InputRow>
      <Footer>
        <Button
          type="submit"
          variant={'primary'}
          disabled={!stripe || !elements || !ackCheckboxValue}
        >
          Add
        </Button>
        <Button type="submit" variant={'secondary'} onClick={onCancel}>
          Cancel
        </Button>
      </Footer>
      <Modal
        title={'Payment Options & Autopay Details'}
        open={showTermsModal}
        onClose={() => {
          setShowTermsModal(false);
        }}
        hideConfirm={true}
        cancelText={'Close'}
      >
        <div>
          <b>ACH Payment Methods:</b>

          <div>
            When an ACH payment method is marked as “Primary” or is assigned to
            a specific job, an auto-charge will occur on the 1st of every month
            for all invoices that were generated in the previous month.
          </div>
          <br />

          <div>
            Please note: If a new payment type is chosen it will not apply to
            jobs in progress. The new payment type will apply to jobs created
            after the new payment type is selected
          </div>
          <br />

          <b>Credit Card Payment Methods:</b>

          <div>
            When a Credit Card payment method is marked as “Primary” or is
            assigned to a specific job, the assigned card on file will be
            automatically charged as soon as the invoice for those jobs has been
            generated.
          </div>
        </div>
      </Modal>
    </form>
  );
};

const AddPaymentMethodModal = ({ paymentMethods, open, onClose, onReload }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const { addToast } = useToast();

  useEffect(() => {
    if (open) {
      setupIntent()
        .then((response) => {
          console.log('Setup intent in modal:', response);
          const clientSecret = response?.client_secret;

          // TODO: Show improved err handling?
          //   const clientSecret = null;
          if (clientSecret) {
            setClientSecret(clientSecret);
          } else {
            console.error('No client secret in response:', response);
            addToast(
              'error',
              'Payment Setup Error',
              <>
                Unable to set up payment method. Please try adding a payment
                method directly in{' '}
                <a
                  href={`${APP_URL}billing?tab=payment_methods`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Proof
                </a>
                .
              </>,
            );
            onClose();
          }
        })
        .catch((err) => {
          console.error('Setup intent error:', err);
          if (open) {
            addToast(
              'error',
              'Payment Setup Error',
              'Unable to set up payment method. Please try adding a payment method directly in Proof.',
            );
          }
          onClose();
        });
    } else {
      setClientSecret(null);
    }
  }, [open, addToast]);

  // Only render Elements when we have a client secret
  if (!clientSecret) {
    return null;
  }

  return (
    <Modal
      title={'Add Payment Method'}
      open={open}
      onClose={() => {
        onClose();
      }}
      hideCancel={true}
      hideConfirm={true}
    >
      <Elements
        options={{
          clientSecret,
          appearance: {
            theme: 'stripe',
          },
        }}
        stripe={stripePromise}
      >
        <AddForm
          setDefault={paymentMethods.length === 0}
          onAdd={(card) => {
            createPaymentMethod(card)
              .then((r) => {
                onReload(r);
                onClose();
              })
              .catch(console.error);
          }}
          onCancel={() => onClose()}
        />
      </Elements>
    </Modal>
  );
};

export default AddPaymentMethodModal;
