import Modal from "components/Modal";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {getUsers, transferJob} from "utils/api";
import Select from "components/Select";
import Loader from "components/Loader";
import {useToast} from "components/Toast";
import {Required} from "components/Required";

const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`

const Label = styled.label`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: .5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const TransferJobModal = ({theme, job,  open, onSubmit, onClose}) => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState(null);
    const [ownerID, setOwnerID] = useState(null);
    const {addToast} = useToast();
    useEffect(() => {
        if (!users) {
            getUsers().then(u => setUsers(u)).catch(console.error);
        }
        setOwnerID(job.user_id);
    }, [job]);

    const submit = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        if (!ownerID) {
            addToast('error', 'Missing required fields', 'Please choose a new owner.');
            return;
        }
        setLoading(true);
        transferJob(job.id, ownerID).then(() => {
            onSubmit(e);
            setLoading(false);
            addToast('success', 'Success', 'Serve request ownership transferred successfully');
        }).catch(() => {
            setLoading(false);
            addToast('error', 'Error', 'Error transferring serve request');
        })
    };

    return <Modal title={"Transfer ownership"}
                  open={open}
                  onConfirm={submit}
                  onClose={onClose}
                  loading={loading}
                  confirmText={"Transfer"}>
        {(!users || loading) && <Loader />}
        {users && !loading && <form onSubmit={submit}>
            <InputRow>
                <Label>
                    Who in your firm would you like to assign ownership of this serve to?
                    <Required />
                </Label>
                <Select options={users.map(s => ({value: s.id, label: s.name}))}
                        placeholder={"Choose an owner..."}
                        value=  {ownerID}
                        onChange={val => setOwnerID(val)}
                        theme={theme}/>
            </InputRow>
        </form>}
    </Modal>
};

export default TransferJobModal;