import React from 'react';
import styled from 'styled-components'
import Moment from "react-moment";
import moment from "moment";
import Logo from "components/Logo";
import Pill from "components/Pill";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 2rem;
  max-height: 50rem;
  overflow-y: auto;
`;

const Message = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const Initials = styled.div`
  background: ${({theme, type}) => ({
    system: '#041b29',
    'FIRM': theme.colors.primary,
    'PROOF': '#041b29',
    'SERVER': theme.colors.success,
    'INDIVIDUAL': theme.colors.warning,
  }[type])};
  min-width: 4rem;
  max-width: 4rem;
  min-height: 4rem;
  max-height: 4rem;
  border-radius: .4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  color: ${({theme}) => theme.colors.white};

  img {
    width: auto;
    height: 2.4rem;
  }
`

const Meta = styled.div`
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Author = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 500;
`

const Type = styled.div`
  font-weight: 400;
  margin-left: 1rem;

  > svg {
    width: auto !important;
    height: 1.5rem !important;
  }
`

const Date = styled.div`
  flex: 1;
  text-align: right;
  white-space: nowrap;
  color: #576c82;
`

const getInitials = (user) => {
    return (user.first_name[0] + user.last_name[0]).toUpperCase();
}

export const ChatMessages = ({messages, bodyRef}) => (
    <Root ref={bodyRef}>
        {messages.length === 0 && <div>Send a chat message below to start a conversation with your process server and/or Proof.</div>}
        {messages.map((message, i) => <Message key={i}>
            {!message.system && message.user &&
                <Initials type={message.user.type}>{getInitials(message.user)}</Initials>}
            {message.system && <Initials type={'system'}>
                <img src={"/images/logo-icon-white.png"}/>
            </Initials>}
            <div>
                <Meta>
                    <Author>{message.system ? 'System' : (message.user?.name ?? 'Deleted User')}</Author>
                    {message.user && <Type>
                        {(!message.system && message.user?.type !== 'PROOF') && <Pill variant={{
                            'FIRM': 'primary',
                            'SERVER': 'success',
                            'INDIVIDUAL': 'warning',
                        }[message.user.type]}>
                            {({
                                'FIRM': 'Firm',
                                'SERVER': 'Server',
                                'PROOF': <Logo/>,
                                'SYSTEM': 'System',
                            }[message.user.type ?? 'SYSTEM'])}
                        </Pill>}
                        {(message.system || message.user?.type === 'PROOF') && <Logo/>}
                    </Type>}
                </Meta>
                <div>{message.message}</div>
            </div>
            <Date title={moment(message.created_at).format("LLL")}>
                <Moment fromNow={true} date={message.created_at}/>
            </Date>
        </Message>)}
    </Root>
);