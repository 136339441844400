import React from 'react';
import styled, {css} from 'styled-components'
import {clio} from "themes/clio";
import {myCase} from "themes/mycase";

const variantStyles = (theme) => {
    switch (theme) {
        case myCase:
            return css`
              background-color: ${({theme}) => theme.colors.white};
              border: 1px solid #ddd;
              border-radius: 4px;
            `
        case clio:
        default:
            return css`
              background-color: ${({theme}) => theme.colors.white};
              border: 1px solid #ccd9e0;
              border-radius: .2rem;
            `
    }
};

const variantHeaderStyles = (theme) => {
    switch (theme) {
        case myCase:
            return css`
              background: rgba(0, 0, 0, 0.03);
              font-size: 1.6rem;
              font-weight: 700;
              padding: .8rem 2rem;
              border-bottom: 1px solid #ddd;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
            `
        case clio:
        default:
            return css`
              font-size: 1.6rem;
              font-weight: 700;
              padding: .8rem 2rem;
              border-bottom: 1px solid #ccd9e0;
            `
    }
};

const variantBodyStyles = (theme) => {
    switch (theme) {
        case clio:
        default:
            return css`
              padding: 2rem;
            `
    }
};

const variantBodySyles = (theme) => {
    switch (theme) {
        case clio:
        default:
            return css`
              color: ${({theme}) => theme.colors.link};
              text-decoration: underline;
            `
    }
};

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  ${({theme, variant}) => variantStyles(theme, variant)}
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  ${({theme, variant}) => variantHeaderStyles(theme, variant)}
`;

const Body = styled.div`
  ${({theme, variant}) => variantBodyStyles(theme, variant)}
  ${({noPadding}) => noPadding ? `padding: 0;` : null}
`;

const Buttons = styled.div`
> *:not(:last-child) {
  margin-right: 1rem;
}
`

const Button = styled.div`
display:inline-block;
  > *:not(:last-child) {
    margin-right: 1rem;
  }
`

export const Card = ({title, children, noPadding, buttons}) => (
        <StyledCard>
            <Header>
                {title}
                {buttons && <Buttons>
                    {buttons.map((button, i) => <Button key={i}>{button}</Button>)}
                </Buttons>}
            </Header>
            <Body noPadding={noPadding}>{children}</Body>
        </StyledCard>
);