export const TYPE_BAD_ADDRESS = 'BAD_ADDRESS';
export const TYPE_MISSING_INFORMATION = 'MISSING_INFORMATION';
export const TYPE_AFFIDAVIT_CHANGES = 'AFFIDAVIT_CHANGES';
export const TYPE_ON_HOLD = 'ON_HOLD';
export const TYPE_PENDING_CANCELLATION = 'PENDING_CANCELLATION';
export const TYPE_FILING_EXCEPTION = 'FILING_EXCEPTION';
export const TYPE_AWAITING_CITATION = 'AWAITING_CITATION';
export const TYPE_OTHER = 'OTHER';

export const TYPE_EXTRA_VACANT_HOME = 'VACANT_HOME';
export const TYPE_EXTRA_ABANDONED_LOT = 'ABANDONED_LOT';
export const TYPE_EXTRA_HOME_FOR_SALE = 'HOME_FOR_SALE';
export const TYPE_EXTRA_NEW_RESIDENT = 'NEW_RESIDENT';
export const TYPE_EXTRA_CONFIRMED_WITH_NEIGHBOR = 'CONFIRMED_WITH_NEIGHBOR';
export const TYPE_EXTRA_MISSING_DOCUMENTS = 'MISSING_DOCUMENTS';
export const TYPE_EXTRA_POOR_SCAN = 'POOR_SCAN';
export const TYPE_EXTRA_INCOMPLETE_ADDRESS = 'INCOMPLETE_ADDRESS';
export const TYPE_EXTRA_INCORRECT_DETAILS = 'INCORRECT_DETAILS';
export const TYPE_EXTRA_COURT_RESPONSE = 'COURT_RESPONSE';
export const TYPE_EXTRA_DOCUMENT_ISSUE = 'DOCUMENT_ISSUE';
export const TYPE_EXTRA_ETA_GIVEN = 'ETA_GIVEN';
export const TYPE_EXTRA_NO_ETA = 'NO_ETA';

export const getNiceFlagType = type => {
    switch (type) {
        case TYPE_BAD_ADDRESS:
            return 'Bad Address';
        case TYPE_MISSING_INFORMATION:
            return 'Missing Information';
        case TYPE_AFFIDAVIT_CHANGES:
            return 'Affidavit Changes';
        case TYPE_ON_HOLD:
            return 'On Hold';
        case TYPE_PENDING_CANCELLATION:
            return 'Pending Cancellation';
        case TYPE_FILING_EXCEPTION:
            return 'Filing Exception';
        case TYPE_AWAITING_CITATION:
            return 'Awaiting Citation';
        case TYPE_OTHER:
            return 'Other';
        default:
            return 'Unknown';
    }
}

export const getNiceFlagTypeExtra = flag => {
    switch (flag.type) {
        case TYPE_EXTRA_VACANT_HOME:
            return 'Vacant Home';
        case TYPE_EXTRA_ABANDONED_LOT:
            return 'Abandoned Lot';
        case TYPE_EXTRA_HOME_FOR_SALE:
            return 'Home for Sale';
        case TYPE_EXTRA_NEW_RESIDENT:
            return 'New Resident';
        case TYPE_EXTRA_CONFIRMED_WITH_NEIGHBOR:
            return 'Confirmed with Neighbor';
        case TYPE_EXTRA_MISSING_DOCUMENTS:
            return 'Missing / Incomplete Documents';
        case TYPE_EXTRA_POOR_SCAN:
            return 'Poorly Scanned Document';
        case TYPE_EXTRA_INCOMPLETE_ADDRESS:
            return 'Incomplete Address';
        case TYPE_EXTRA_INCORRECT_DETAILS:
            return 'Incorrect/Incomplete Details';
        case TYPE_EXTRA_COURT_RESPONSE:
            return 'Response from Court';
        case TYPE_EXTRA_DOCUMENT_ISSUE:
            return 'Issue With Documents';
        case TYPE_EXTRA_ETA_GIVEN:
            return 'ETA Given';
        case TYPE_EXTRA_NO_ETA:
            return 'No ETA Given';
    }
}

export const getFlagDetails = flag => {
    let details = '';
    details +=
        (flag.created_by ? flag.created_by.name : 'Deleted User') +
        ' flagged the job for ' +
        getNiceFlagType(flag.type) +
        (flag.type_extra ? ` (${getNiceFlagTypeExtra(flag.type_extra)})` : '');
    if (flag.description) {
        details += ': \n' + flag.description;
    }
    if (flag.resolved) {
        details +=
            '\n\nResolved by ' +
            (flag.resolved_by ? flag.resolved_by.name : 'Deleted User') +
            ': \n' +
            flag.resolution_notes;
    }
    return details;
}