import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { clio } from 'themes/clio';
import { litify } from 'themes/litify';
import { myCase } from 'themes/mycase';
import { filevine } from 'themes/filevine';

const container = document.getElementById('app');
const root = ReactDOM.createRoot(container);

let theme = clio;
let params = new URLSearchParams(window.location.search);
let lpms = params.get('lpms');
if (lpms) {
  if (lpms.toLowerCase() === litify.id.toLowerCase()) {
    theme = litify;
  } else if (lpms.toLowerCase() === myCase.id.toLowerCase()) {
    theme = myCase;
  } else if (lpms.toLowerCase() === filevine.id.toLowerCase()) {
    theme = filevine;
  }
}
root.render(<App theme={theme} />);

if (module.hot) {
  module.hot.accept();
}
