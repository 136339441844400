import React from 'react';
import styled, { css } from 'styled-components';
import { clio } from 'themes/clio';
import { myCase } from 'themes/mycase';
import { filevine } from 'themes/filevine';
import { Link } from 'react-router-dom';

const variantStyles = (theme, variant = 'primary') => {
  switch (theme) {
    case filevine:
      const fvBase = css`
        border-radius: 0.3rem;
        color: #ffffff; // Setting white text color
        padding: ${({ size }) =>
          size === 'small'
            ? '.35rem .8rem'
            : size === 'big'
              ? '1.5rem 2rem'
              : '.725rem 1.2rem'};
        text-align: center;
        font-size: ${({ size }) => (size === 'big' ? '1.4rem' : '1.3rem')};
        font-weight: 700;
        border: 1px solid transparent;
        -webkit-font-smoothing: antialiased;
      `;

      return {
        primary: css`
          ${fvBase};
          background-color: #009d9a;
          border-color: #009d9a;

          &:hover {
            background-color: #08bdba;
            border-color: #08bdba;
          }

          &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
          }
        `,
        secondary: css`
          ${fvBase};
          color: #333333;
          background-color: #f5f7f8;
          border-color: #dee2e6;

          &:hover {
            background-color: #e9ecef;
          }
        `,
        destructive: css`
          ${fvBase};
          background-color: ${theme.colors.error};
          border-color: ${theme.colors.error};

          &:hover {
            opacity: 0.9;
          }
        `,
        warning: css`
          ${fvBase};
          background-color: ${theme.colors.warning};
          border-color: ${theme.colors.warning};

          &:hover {
            opacity: 0.9;
          }
        `,
      }[variant];
    case myCase:
      const mcBase = css`
        color: ${(props) => props.theme.colors.white};
        padding: ${({ size }) =>
          size === 'small'
            ? '.4rem .8rem'
            : size === 'big'
              ? '1.6rem 2rem'
              : '1rem 1.2rem'};
        text-align: center;
        font-size: ${({ size }) => (size === 'big' ? '1.4rem' : '1.3rem')};
        font-weight: 700;
        border: 0;
        text-shadow: 1px 1px 1px rgb(0 0 0 / 0%);
        -webkit-font-smoothing: antialiased;
        border-radius: 32px;
        line-height: 1;
      `;
      return {
        primary: css`
          ${mcBase};
          background-color: ${theme.colors.link};
          &:hover {
            opacity: 0.9;
          }
        `,
        secondary: css`
          ${mcBase};
          color: #001c37;
          background-color: #fff;
          border: 1px solid #2c2c2c;
          &:hover {
            color: #fff;
            background: #2c2c2c;
          }
        `,
        destructive: css`
          ${mcBase};
          background-color: ${theme.colors.destructive};
        `,
        warning: css`
          ${mcBase};
          background-color: ${theme.colors.warning};
          background-image: linear-gradient(
            -180deg,
            ${theme.colors.destructive} 0%,
            #ab101b 100%
          );
        `,
      }[variant];
    case clio:
    default:
      const clioBase = css`
        border-radius: 0.3rem;
        color: ${(props) => props.theme.colors.white};
        padding: ${({ size }) =>
          size === 'small'
            ? '.35rem .8rem'
            : size === 'big'
              ? '1.5rem 2rem'
              : '.725rem 1.2rem'};
        text-align: center;
        font-size: ${({ size }) => (size === 'big' ? '1.4rem' : '1.3rem')};
        font-weight: 700;
        border: 1px solid transparent;
        text-shadow: 1px 1px 1px rgb(0 0 0 / 0%);
        -webkit-font-smoothing: antialiased;

        &:hover:before {
          content: '';
          border-bottom: 2px solid ${theme.colors[variant + '_bg']};
          position: absolute;
          bottom: 1px;
          left: 1px;
          right: 1px;
          border-radius: 0px 0px 1px 1px;
        }
      `;
      return {
        primary: css`
          ${clioBase};
          background-color: ${theme.colors.link};
          background-image: linear-gradient(-180deg, #0070e0 0%, #0f478d 100%);
          border-color: #0f478d;

          &:hover {
            background-image: linear-gradient(
              -180deg,
              #0f478d 2%,
              #0f478d 100%
            );
          }

          &:disabled,
          &:hover[disabled] {
            background-image: none;
            cursor: not-allowed;
          }

          &:disabled {
            color: #a7c2dd;
            background-color: #e8f6ff;
            border-color: #d3eeff;
          }
        `,
        secondary: css`
          ${clioBase};
          color: #001c37;
          background-color: #f5f7f8;
          background-image: linear-gradient(-180deg, #fff 0%, #f5f7f8 100%);
          border-color: #b0bec5;

          &:hover {
            background-image: linear-gradient(
              -180deg,
              #f6f8f9 0%,
              #dfe6e9 100%
            );

            :before {
              border-color: #576c82;
            }
          }
        `,
        destructive: css`
                  ${clioBase};
                  background-color: ${theme.colors.destructive};
                  background-image: linear-gradient(-180deg, ${theme.colors.destructive} 0%, #ab101b 100%);
                  border-color: #a60110;

                  &:hover {
                    background-image: linear-gradient(-180deg, #d3131f 0%, #b6000c 100%);
                  }
                ,
                `,
        warning: css`
                  ${clioBase};
                  background-color: ${theme.colors.warning};
                  background-image: linear-gradient(-180deg, ${theme.colors.destructive} 0%, #ab101b 100%);
                  border-color: #a60110;

                  &:hover {
                    background-image: linear-gradient(-180deg, #d3131f 0%, #b6000c 100%);
                  }

                ,
                `,
      }[variant];
  }
};

const StyledButton = styled.button`
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  position: relative;
  ${({ theme, variant }) => variantStyles(theme, variant)}
`;

const Button = ({
  theme,
  size,
  variant,
  disabled,
  to,
  target,
  onClick,
  children,
}) => {
  if (to && (to.startsWith('http://') || to.startsWith('https://'))) {
    return (
      <a href={to} target={target}>
        <StyledButton
          theme={theme}
          variant={variant}
          size={size}
          disabled={disabled}
        >
          {children}
        </StyledButton>
      </a>
    );
  } else if (to) {
    return (
      <Link to={to} target={target}>
        <StyledButton
          theme={theme}
          variant={variant}
          size={size}
          disabled={disabled}
        >
          {children}
        </StyledButton>
      </Link>
    );
  }
  return (
    <StyledButton
      theme={theme}
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
