import React from 'react';
import Lottie from 'react-lottie';
import animationData from './Loader.lottie.json';
import styled from 'styled-components'

const Root = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LoaderContainer = styled.div`
  max-width: 10rem;
  ${({small}) => small ? 'max-width: 5rem;' : ''}
`


const Loader = ({small}) => (
    <Root>
        <LoaderContainer small={small}>
            <Lottie options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                }
            }
            } height={'auto'}
                    width={'100%'}
                    isStopped={false}
                    isPaused={false}/>
        </LoaderContainer>
    </Root>
);

export default Loader;