import { Checkbox } from "components/Checkbox";
import { Input } from "components/Input";
import Loader from "components/Loader";
import { Modal } from "components/Modal";
import { useToast } from "components/Toast";
import React, { useState } from "react";
import styled from "styled-components";
import {Required} from "components/Required";

const InputRow = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }
`

const Label = styled.label`
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: .5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const InputGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  > * {
    flex: 1;
    margin-bottom: 0 !important;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }
`

const NewPricingWarning = styled.div`
    font-size: 1.5rem;
    color: #991b1e;
    font-weight: 400;
    margin-top: 1.2rem;
`

const AddSpecialRequirementsModal = ({theme, open, defaultsCount, onClose}) => {
    const [loading, setLoading] = useState(false);
    const [specialRequirement, setSpecialRequirement] = useState({
        title: '',
        instructions: '',
        save_for_later: false,
        is_default: false,
    });
    const {addToast} = useToast();

    const submit = (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        if (!specialRequirement.title || !specialRequirement.instructions) {
            addToast('error', 'Missing required fields', 'Please fill out all required fields');
            return;
        }

        if (!specialRequirement.save_for_later) {
            specialRequirement.is_default = false
        }
        if (specialRequirement.is_default && defaultsCount >= 8) {
            specialRequirement.is_default = false
        }

        setLoading(true);
        onClose({
            title: specialRequirement.title,
            instructions: specialRequirement.instructions,
            save_for_later: specialRequirement.save_for_later,
            is_default: specialRequirement.is_default,
        })
        addToast('success', 'Success', 'Requirement added');
        setLoading(false);
        specialRequirement.title = '';
        specialRequirement.instructions = '';
        specialRequirement.save_for_later = false;
        specialRequirement.is_default = false;
    };

    const close = (e) => {
        specialRequirement.title = '';
        specialRequirement.instructions = '';
        specialRequirement.save_for_later = false;
        specialRequirement.is_default = false;
        onClose()
    }

    return <Modal title={"Add requirement"}
        open={open}
        onConfirm={submit}
        onClose={close}
        loading={loading}
        defaultsCount={defaultsCount}
        confirmText={"Add requirement"}>
        {loading && <Loader/>}
        {!loading && <form onSubmit={submit}>
            <InputGroup>
                <InputRow>
                    <Label>
                        Title
                        <Required />
                    </Label>
                    <Input 
                        type={"text"}
                        value={specialRequirement.title}
                        required={true}
                        onChange={val => setSpecialRequirement({...specialRequirement, title: val})}
                        theme={theme}
                    />
                </InputRow>
            </InputGroup>
            <InputGroup>
                <InputRow>
                    <Label>
                        Instructions
                        <Required />
                    </Label>
                    <Input 
                        type={"textarea"}
                        value={specialRequirement.instructions}
                        required={true}
                        onChange={val => setSpecialRequirement({...specialRequirement, instructions: val})}
                        theme={theme}
                    />
                </InputRow>
            </InputGroup>
            <Checkbox 
                title={"Save for later"}
                value={specialRequirement.save_for_later}
                onChange={val => setSpecialRequirement({...specialRequirement, save_for_later: val})}
                theme={theme}
            />
            {specialRequirement.save_for_later && defaultsCount < 8 && (
            <Checkbox 
                title={"Set as default"}
                value={specialRequirement.is_default}
                onChange={val => setSpecialRequirement({...specialRequirement, is_default: val})}
                theme={theme}
            />)}
        </form>}
    </Modal>
};

export default AddSpecialRequirementsModal;