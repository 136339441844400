import React, {useState} from 'react';
import styled, {css} from 'styled-components'
import {clio} from "themes/clio";

const variantStyles = (theme) => {
    switch (theme) {
        case clio:
        default:
            return css`
              border-bottom: 1px solid #ccd9e0;
              padding: 1rem 2rem;
            `
    }
};

const variantHeaderStyles = (theme) => {
    switch (theme) {
        case clio:
        default:
            return css`
              font-size: 1.6rem;
              font-weight: 700;
              padding: .8rem 0;
            `
    }
};

const variantBodyStyles = (theme) => {
    switch (theme) {
        case clio:
        default:
            return css`
              padding: 2rem;
            `
    }
};

const variantBodySyles = (theme) => {
    switch (theme) {
        case clio:
        default:
            return css`
              color: ${({theme}) => theme.colors.link};
              text-decoration: underline;
            `
    }
};

const StyledAccordion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({theme, variant}) => variantStyles(theme, variant)}
`;

const Header = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  > * {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  ${({theme, variant}) => variantHeaderStyles(theme, variant)}
`;

const PillContainer = styled.div`
  display: inline-block;
  margin-right: 1.2rem;
`

const Body = styled.div`
  ${({theme, variant}) => variantBodyStyles(theme, variant)}
  ${({noPadding}) => noPadding ? `padding: 0;` : null}
  display: ${({open}) => open ? 'block' : 'none'}
`;

export const Accordion = ({title, pill, children, noPadding}) => {
    const [open, setOpen] = useState(false);
    return <StyledAccordion>
        <Header onClick={() => setOpen(!open)}>
            <div>{title}</div>
            <div>
                {pill && <PillContainer>
                    {pill}
                </PillContainer>}
                {open ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                             className="bi bi-chevron-up" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                    </svg>
                    : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                           className="bi bi-chevron-down" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                    </svg>}
            </div>
        </Header>
        <Body noPadding={noPadding} open={open}>{children}</Body>
    </StyledAccordion>
};